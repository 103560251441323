var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c("h4", { staticClass: "text-uppercase" }, [_vm._v("Other Applicants")]),
      _vm._l(_vm.applicants, function(item, index) {
        return [
          _c(
            "div",
            { key: index, staticClass: "my-2" },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("strong", [_vm._v(_vm._s(item.name))]),
                  _c(
                    "v-chip",
                    {
                      attrs: {
                        small: "",
                        color:
                          "secondary lighten-2 text-subtitle-2 text-uppercase ml-2"
                      }
                    },
                    [_vm._v(_vm._s(item.type))]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "", color: "secondary" }
                    },
                    [_vm._v("mdi-email")]
                  ),
                  _vm._v(" " + _vm._s(item.email) + " ")
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-2",
                      attrs: { small: "", color: "secondary" }
                    },
                    [_vm._v("mdi-phone")]
                  ),
                  _vm._v(" " + _vm._s(item.phone) + " ")
                ],
                1
              ),
              index != _vm.applicants.length - 1
                ? _c("v-divider", { staticClass: "mt-1" })
                : _vm._e()
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }