<template>
    <div class="d-flex flex-column">
        <h4 class="text-uppercase">Other Applicants</h4>
        <template v-for="(item, index) in applicants">
            <div :key="index" class="my-2">
                <div class="d-flex">
                    <strong>{{ item.name }}</strong
                    ><v-chip
                        small
                        color="secondary lighten-2 text-subtitle-2 text-uppercase ml-2"
                        >{{ item.type }}</v-chip
                    >
                </div>
                <div class="d-flex">
                    <v-icon small color="secondary" class="mr-2"
                        >mdi-email</v-icon
                    >
                    {{ item.email }}
                </div>
                <div class="d-flex">
                    <v-icon small color="secondary" class="mr-2"
                        >mdi-phone</v-icon
                    >
                    {{ item.phone }}
                </div>
                <v-divider
                    class="mt-1"
                    v-if="index != applicants.length - 1"
                ></v-divider>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: "applicant-profile-applicants",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        applicants() {
            return this.applicant.application.applicants.filter(
                (item) => item.applicant_id !== this.applicant.applicant_id
            );
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>